.homeContainer {
    background-image: url('../assets/home_landing.webp');
    background-size: cover;
    background-position: center;
    color: white;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.homeHeading {
    color: rgb(225, 234, 143);
    font-style: oblique;
    transform: translateY(-120px);
}

.contentContainer {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.testimonials {
    background-color: rgba(52, 116, 189, 0.342);
    flex: 1;
    height: 100px;
    overflow: hidden;
    margin-right: 150px;
    margin-left: 20px;
    margin-top: 60px;
    padding: 10px;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    border-radius: 15px;
    position: relative;
}

.testimonialItem {
    width: 100%;
    position: absolute;
    text-align: center;
    animation: slide-in 10s linear infinite;
}

.testimonials h2 {
    text-align: center;
    color: pink;
    text-shadow: 0 0 10px;
}

@keyframes slide-in {
    0%, 20% {
        top: 100%;
        opacity: 0;
    }
    25%, 45% {
        top: 50%;
        transform: translateY(-50%);
        opacity: 1;
    }
    50%, 100% {
        top: -100%;
        opacity: 0;
    }
}

.signupForm {
    flex: 1;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    border-radius: 15px;
    margin-right: 80px;
    width: 100px;
    align-items: center;
    justify-content: center;
}

.signupForm h2 {
    text-align: center;
    color: pink;
    text-shadow: 0 0 10px;
}

.signupForm form label {
    display: block;
    margin-bottom: 10px;
}

.signupForm form input,
.signupForm form button {
    width: 80%;
    padding: 8px;
    margin-top: 5px;
    border-radius: 5px;
}

.signupForm form button {
    width: fit-content;
}