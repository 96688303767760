.footer {
    background-color: #c6f58d;
    color: rgb(4, 4, 4);
    font-size: small;
    text-align: center;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 1000;
}
