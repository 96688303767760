.header {
    background-color: #c6f58d81;
    color: white;
    padding: 0px 0px;
    text-align: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1001; /* Higher z-index to ensure it stays on top */
    letter-spacing: 2px;
    word-spacing: 5px;
}

.header a {
    color: blue;
    text-decoration: underline;
}
